const closeSaleBannerKey = 'closeSaleBanner';
const closeSaleBannerButton = document.getElementById('closeSaleBanner');

// CLoses sale pop up
function closeBanner() {
  const saleElement = document.getElementsByClassName('sale-banner');
  saleElement[0].classList.remove('active');

  // Sets local storage value to tomorrows date
  const hideUntilNow = new Date();
  hideUntilNow.setDate(hideUntilNow.getDate() + 1);
  localStorage.setItem(closeSaleBannerKey, hideUntilNow);
}

function updateCountdown() {
  const targetDate = new Date(window.sale_countdown_time);
  const daysBox = document.getElementById('days');
  const hoursBox = document.getElementById('hours');
  const minutesBox = document.getElementById('minutes');
  const secondsBox = document.getElementById('seconds');

  const now = new Date();
  let diffInSeconds = Math.floor((targetDate - now) / 1000);

  if (diffInSeconds < 0) {
    // Time's up
    daysBox.value = 0;
    hoursBox.value = 0;
    minutesBox.value = 0;
    secondsBox.value = 0;
    return;
  }

  const days = Math.floor(diffInSeconds / (60 * 60 * 24));
  diffInSeconds -= days * (60 * 60 * 24);
  const hours = Math.floor(diffInSeconds / (60 * 60));
  diffInSeconds -= hours * (60 * 60);
  const minutes = Math.floor(diffInSeconds / 60);
  diffInSeconds -= minutes * 60;
  const seconds = diffInSeconds;

  daysBox.innerHTML = days;
  hoursBox.innerHTML = hours;
  minutesBox.innerHTML = minutes;
  secondsBox.innerHTML = seconds;
}

if (closeBanner && closeSaleBannerButton) {
  closeSaleBannerButton.addEventListener('click', closeBanner);

  // Gets local storage data value (If added)
  const hideUntil = new Date(localStorage.getItem(closeSaleBannerKey) || 0);
  // Todays date time is passed the hideUntil value, show banner again.
  if (new Date() > hideUntil) {
    setTimeout(() => {
      const saleElement = document.getElementsByClassName('sale-banner');
      saleElement[0].classList.add('active');
    }, 4000);
  }

  // Start the countdown to a specific date
  setInterval(updateCountdown, 1000);
}
