// To parse this data:
//
//   import { Convert, RevenueCatUser } from "./file";
//
//   const revenueCatUser = Convert.toRevenueCatUser(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

import axios, {AxiosRequestConfig} from "axios";
import {plainToClass} from "class-transformer";

export interface RevenueCatUser {
  request_date:    Date;
  request_date_ms: number;
  subscriber:      Subscriber;
}

export interface Subscriber {
  entitlements:                 Entitlements | null;
  first_seen:                   Date;
  last_seen:                    Date;
  management_url:               null;
  non_subscriptions:            object;
  original_app_user_id:         string;
  original_application_version: string;
}

export interface Entitlements {
  PRO:     Entitlement;
  "PRO +": Entitlement;
}

export interface Entitlement {
  expires_date:              Date | null;
  grace_period_expires_date: null;
  product_identifier:        string;
  purchase_date:             Date;
}


export class RevenueCatUtil {
  revenueCatUser: RevenueCatUser;

  constructor(revenueCatUser: RevenueCatUser) {
    this.revenueCatUser = revenueCatUser;
  }

  isPro(): boolean {
    if (this.revenueCatUser == null) {
      return  false
    }
    if (this.revenueCatUser.subscriber == null) {
      return false
    }
    return (this.revenueCatUser.subscriber.entitlements && this.revenueCatUser.subscriber.entitlements.PRO &&
      this.revenueCatUser.subscriber.entitlements.PRO.expires_date != null &&
      new Date(this.revenueCatUser.subscriber.entitlements.PRO.expires_date) > new Date()) || this.isProPlus()
  }

  isProPlus(): boolean {
    if (this.revenueCatUser == null) {
      return  false
    }
    if (this.revenueCatUser.subscriber == null) {
      return false
    }
    return this.revenueCatUser.subscriber.entitlements != null && this.revenueCatUser.subscriber.entitlements["PRO +"] && this.revenueCatUser.subscriber.entitlements["PRO +"].expires_date != null &&
      new Date(this.revenueCatUser.subscriber.entitlements["PRO +"].expires_date) > new Date();
  }

  public static async get(): Promise<RevenueCatUtil | null> {
    if (window.user == null) {
      return null
    }
    const options: AxiosRequestConfig = {
      method: 'GET',
      // @ts-ignore
      url: 'https://api.revenuecat.com/v1/subscribers/' + window.user.firebase_user_id,
      headers: {
        accept: 'application/json',
        'X-Platform': 'web',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.rcPublicKey}`
      }
    };
    try {
      const revCatResponse = await axios.request(options)
      const newObj = {
        revenueCatUser: revCatResponse.data
      }
      return plainToClass(RevenueCatUtil, newObj)
    } catch(e) {
      return null;
    }
  }

  public static toRevenueCatUser(json: string): RevenueCatUtil {
    let revenueCatUser: RevenueCatUser = cast(JSON.parse(json), r("RevenueCatUser"));
    return  new RevenueCatUtil(revenueCatUser)
  }

  public static revenueCatUserToJson(value: RevenueCatUser): string {
    return JSON.stringify(uncast(value, r("RevenueCatUser")), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any = ''): never {
  if (key) {
    throw Error(`Invalid value for key "${key}". Expected type ${JSON.stringify(typ)} but got ${JSON.stringify(val)}`);
  }
  throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`, );
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ''): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {}
    }
    return invalidValue(typs, val);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(cases, val);
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue("array", val);
    return val.map(el => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue("Date", val);
    }
    return d;
  }

  function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
    if (val === null || typeof val !== "object" || Array.isArray(val)) {
      return invalidValue("object", val);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach(key => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, prop.key);
    });
    Object.getOwnPropertyNames(val).forEach(key => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key);
      }
    });
    return result;
  }

  if (typ === "any") return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val);
  }
  if (typ === false) return invalidValue(typ, val);
  while (typeof typ === "object" && typ.ref !== undefined) {
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === "object") {
    return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
        : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
          : invalidValue(typ, val);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  "RevenueCatUser": o([
    { json: "request_date", js: "request_date", typ: Date },
    { json: "request_date_ms", js: "request_date_ms", typ: 0 },
    { json: "subscriber", js: "subscriber", typ: r("Subscriber") },
  ], false),
  "Subscriber": o([
    { json: "entitlements", js: "entitlements", typ: r("Entitlements") },
    { json: "first_seen", js: "first_seen", typ: Date },
    { json: "last_seen", js: "last_seen", typ: Date },
    { json: "management_url", js: "management_url", typ: null },
    { json: "non_subscriptions", js: "non_subscriptions", typ: r("NonSubscriptions") },
    { json: "original_app_user_id", js: "original_app_user_id", typ: "" },
    { json: "original_application_version", js: "original_application_version", typ: "" },
    { json: "original_purchase_date", js: "original_purchase_date", typ: Date },
    { json: "other_purchases", js: "other_purchases", typ: r("OtherPurchases") },
    { json: "subscriptions", js: "subscriptions", typ: r("Subscriptions") },
  ], false),
  "Entitlements": o([
    { json: "PRO", js: "PRO", typ: r("Pro") },
    { json: "PRO +", js: "PRO +", typ: r("Pro") },
  ], false),
  "Pro": o([
    { json: "expires_date", js: "expires_date", typ: u(Date, null) },
    { json: "grace_period_expires_date", js: "grace_period_expires_date", typ: null },
    { json: "product_identifier", js: "product_identifier", typ: "" },
    { json: "purchase_date", js: "purchase_date", typ: Date },
  ], false),
  "NonSubscriptions": o([
    { json: "com.hiiker.pro.3_year.sale", js: "com.hiiker.pro.3_year.sale", typ: a(r("COMHiikerPro3_YearSaleElement")) },
    { json: "com.hiiker.pro.three_year", js: "com.hiiker.pro.three_year", typ: a(r("COMHiikerPro3_YearSaleElement")) },
    { json: "com.hiiker.pro_plus.three_year", js: "com.hiiker.pro_plus.three_year", typ: a(r("COMHiikerPro3_YearSaleElement")) },
    { json: "com.hiiker.pro_plus.three_year.sale", js: "com.hiiker.pro_plus.three_year.sale", typ: a(r("COMHiikerPro3_YearSaleElement")) },
  ], false),
  "COMHiikerPro3_YearSaleElement": o([
    { json: "id", js: "id", typ: "" },
    { json: "is_sandbox", js: "is_sandbox", typ: true },
    { json: "original_purchase_date", js: "original_purchase_date", typ: Date },
    { json: "purchase_date", js: "purchase_date", typ: Date },
    { json: "store", js: "store", typ: r("Store") },
  ], false),
  "OtherPurchases": o([
    { json: "com.hiiker.pro.3_year.sale", js: "com.hiiker.pro.3_year.sale", typ: r("OtherPurchasesCOMHiikerPro3_YearSale") },
    { json: "com.hiiker.pro.three_year", js: "com.hiiker.pro.three_year", typ: r("OtherPurchasesCOMHiikerPro3_YearSale") },
    { json: "com.hiiker.pro_plus.three_year", js: "com.hiiker.pro_plus.three_year", typ: r("OtherPurchasesCOMHiikerPro3_YearSale") },
    { json: "com.hiiker.pro_plus.three_year.sale", js: "com.hiiker.pro_plus.three_year.sale", typ: r("OtherPurchasesCOMHiikerPro3_YearSale") },
  ], false),
  "OtherPurchasesCOMHiikerPro3_YearSale": o([
    { json: "purchase_date", js: "purchase_date", typ: Date },
  ], false),
  "Subscriptions": o([
    { json: "com.hiiker.pro_plus.annual", js: "com.hiiker.pro_plus.annual", typ: r("COMHiikerProPlusAnnual") },
    { json: "com.hiiker.pro_plus.monthly", js: "com.hiiker.pro_plus.monthly", typ: r("COMHiikerProPlusAnnual") },
    { json: "rc_promo_PRO +_yearly", js: "rc_promo_PRO +_yearly", typ: r("COMHiikerProPlusAnnual") },
  ], false),
  "COMHiikerProPlusAnnual": o([
    { json: "billing_issues_detected_at", js: "billing_issues_detected_at", typ: null },
    { json: "expires_date", js: "expires_date", typ: Date },
    { json: "grace_period_expires_date", js: "grace_period_expires_date", typ: null },
    { json: "is_sandbox", js: "is_sandbox", typ: true },
    { json: "original_purchase_date", js: "original_purchase_date", typ: Date },
    { json: "ownership_type", js: "ownership_type", typ: u(undefined, "") },
    { json: "period_type", js: "period_type", typ: "" },
    { json: "purchase_date", js: "purchase_date", typ: Date },
    { json: "store", js: "store", typ: "" },
    { json: "unsubscribe_detected_at", js: "unsubscribe_detected_at", typ: u(Date, null) },
  ], false),
  "Store": [
    "app_store",
  ],
};
