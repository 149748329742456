<template>
  <div class="loading-dialog" v-if="showLoadingDialog && !forceNotShow" @click="forceNotShow = false">
    <div class="loading-icon">
      <svg class="circular" viewBox="25 25 50 50">
       <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
    </div>
    <div class="loading-text">Loading {{ loadingWhat }} ...</div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoadingDialog',
  props: {
    showLoadingDialog: {
      type: Boolean,
      required: true,
    },
    loadingWhat: {
      type: String,
      default: '', // Default text
    },
  },
  data() {
    return {
      forceNotShow: false,
    };
  },
});
</script>

<style scoped>
.loading-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000000000;
}

.loading-icon {
  width: 50px;
  height: 50px;
}

.loading-text {
  margin-top: 16px;
  font-size: 16px;
  color: white;
}

.circular {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.path {
  stroke: white;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
