import axios from 'axios';

export const token = document.querySelector('[name="csrf-token"]') || { content: 'no-csrf-token' };

export default axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': token.content,
    App: 'Web',
  },
});
