import Vue from 'vue';
import Toasted from 'vue-toasted';
import * as bus from '../utils/bus';
import App from './App.vue';

Vue.use(Toasted);
Vue.config.productionTip = false;

window.vue = new Vue({
  render: (h) => h(App),
  beforeCreate() {
    // Instantiate the bus
    bus;
  },
}).$mount('#dynamic_profile_nav');
