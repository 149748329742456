import Splide, { LOOP } from '@splidejs/splide';
import { Grid } from '@splidejs/splide-extension-grid';
import '@splidejs/splide/css';

// $(function () {
//   $('[data-bs-toggle="tooltip"]').tooltip()
// })

const homeFeatureSliderOptions = {
  direction: 'ttb',
  pagination: false,
  perPage: 1,
  drag: false,
  arrows: false,
  type: 'loop',
  heightRatio: 0.45,
  breakpoints: {
    1024: {
      heightRatio: 0.5,
    },
    767: {
      heightRatio: 1.7,
      direction: 'ltr',
      drag: true,
      pagination: true,
    },
    515: {
      heightRatio: 1.9,
    },
    420: {
      heightRatio: 2,
    },
    393: {
      heightRatio: 2.1,
    },
    373: {
      heightRatio: 2.2,
    },
  },
};

const homeSplideNearbyTrails = {
  // rewind: true,
  perPage: 4,
  // fixedWidth: '25%',
  isNavigation: true,
  gap: '20px',
  breakpoints: {
    1024: {
      perPage: 3,
    },
    768: {
      perPage: 2,
    },
    640: {
      fixedWidth: '75%',
      gap: '1em',
      arrows: false,
      pagination: false,
    },
  },
};

const trailsSplideOptions = {
  rewind: true,
  perPage: 3,
  gap: '20px',
  perMove: 1,
  breakpoints: {
    640: {
      type: 'loop',
      perPage: 1.5,
      gap: '1em',
      arrows: false,
      pagination: false,
    },
  },
};

const newFeatureSlideOptions = {
  direction: 'ttb',
  height: '17em',
  type: 'loop',
  breakpoints: {
    1199: {
      height: '15em',
    },
    991: {
      height: '13em',
    },
    767: {
      height: '20em',
    },
  },
};

const quoteSplideOptions = {
  rewind: true,
  perPage: 4,
  gap: '20px',
  perMove: 1,
  breakpoints: {
    1024: {
      perPage: 3,
    },
    768: {
      perPage: 2,
    },
    640: {
      type: 'loop',
      perPage: 1.5,
      gap: '1em',
      arrows: false,
      pagination: false,
    },
  },
};

const purchaseFeatureSplideOptions = {
  rewind: true,
  perPage: 5,
  gap: '30px',
  pagination: false,
  perMove: 1,
  breakpoints: {
    1024: {
      perPage: 4.5,
    },
    768: {
      perPage: 4.5,
      arrows: false,
      gap: '1em'
    },
    640: {
      type: 'loop',
      perPage: 2.5,
      gap: '1em',
      arrows: false,
      pagination: false
    },
  },
};

const trailOverviewImages = {
  type   : 'loop',
  padding: '8rem',
  gap: '1rem',
  arrows: true,
  breakpoints: {
    768: {
      padding: '1rem',
    }
  }
};

const mapPreviewsSplideOptions = {
  rewind: true,
  perPage: 2.5,
  gap: '10px',
  perMove: 1,
  pagination: false,
  breakpoints: {
    640: {
      type: 'loop',
      perPage: 2.5,
      gap: '1em',
      arrows: false,
    },
  },
};

const homeFeatureSliderElement = document.getElementById('home-feature-slider');
let homeFeatureSlider;

if (homeFeatureSliderElement) {
  homeFeatureSlider = new Splide(homeFeatureSliderElement, homeFeatureSliderOptions).mount();
}

$('#btn-next').on('click', function () {
  console.log('next');
  homeFeatureSlider.go('>');
});

$('#btn-prev').on('click', function () {
  console.log('prev');
  homeFeatureSlider.go('<');
});

const splideNearbyTrails = document.getElementById('splide-nearby-trails');
if (splideNearbyTrails) {
  new Splide(splideNearbyTrails, homeSplideNearbyTrails).mount();
}

const splideTopTrails = document.getElementById('splide-country-top-trails');
if (splideTopTrails) {
  new Splide(splideTopTrails, trailsSplideOptions).mount();
}

const splideCountryLongTrails = document.getElementById('splide-country-long-trails');
if (splideCountryLongTrails) {
  new Splide(splideCountryLongTrails, trailsSplideOptions).mount();
}

const splideCountryShortTrails = document.getElementById('splide-country-short-trails');
if (splideCountryShortTrails) {
  new Splide(splideCountryShortTrails, trailsSplideOptions).mount();
}

const splideCountryDifficultTrails = document.getElementById('splide-country-difficult-trails');
if (splideCountryDifficultTrails) {
  new Splide(splideCountryDifficultTrails, trailsSplideOptions).mount();
}

const newFeatureSlider = document.getElementById('new-feature-slider');
if (newFeatureSlider) {
  new Splide(newFeatureSlider, newFeatureSlideOptions).mount();
}

const splideFreeQuotesSlider = document.getElementById('splide-free-quotes');
if (splideFreeQuotesSlider) {
  new Splide(splideFreeQuotesSlider, quoteSplideOptions).mount();
}

const purchaseFeatureSlider = document.getElementById('purchase-preview-slider');
if (purchaseFeatureSlider) {
  new Splide(purchaseFeatureSlider, purchaseFeatureSplideOptions).mount();
}

const trailOverviewImagesSlider = document.getElementById('trail-overview-slider');
if (trailOverviewImagesSlider) {
  new Splide(trailOverviewImagesSlider, trailOverviewImages).mount({ Grid });
}

const mapPreviewsSplide = document.getElementById('map-preview-slider');
if (mapPreviewsSplide) {
  new Splide(mapPreviewsSplide, mapPreviewsSplideOptions).mount({ Grid });
}

const youtubeModal = document.getElementById('activateYoutubeModal');
if (youtubeModal) {
  youtubeModal.addEventListener('click', () => {
    const URL = 'https://www.youtube-nocookie.com/embed/rKh-RM_CHQI?loop=1';
    const videoFrame = document.querySelector('.video-frame');
    videoFrame.src = URL;
  });
}

console.log('Splide setup complete');

