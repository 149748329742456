import Rails from '@rails/ujs';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import './css/app.scss';
import './splide/app';
import './js/ahoy';
import './utils/lozad';
import './navbar/main';
import './utils/sale_countdown/main';
import './analytics/analytics';

// DYNAMIC IMPORTING
// Only import reviews if #new_review is in the viewport and visible
const $addReviewButton = document.getElementById('new_review');
if ($addReviewButton) {
 import('./reviews/main');
}

Rails.start();

console.log('Hello World from Hiiker!');
