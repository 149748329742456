import ahoy from 'ahoy.js';

// track ahoy Events
// targets should have at least data-ahoy-event="event_name"

window.bindAhoyEvents = () => {
  const ahoyButtons = Array.prototype.slice.call(document.querySelectorAll('[data-ahoy-event]'));
  if (ahoyButtons.length > 0) {
    ahoyButtons.forEach((button) => {
      // may need to check for already bound events, we don't want to double track
      button.addEventListener('click', (e) => {
        const initialTargetData = e.target.dataset;
        if (initialTargetData.ahoyEvent) {
          ahoy.track(initialTargetData.ahoyEvent, initialTargetData);
        } else {
          const target = e.target.closest('[data-ahoy-event]');
          const data = target.dataset;
          ahoy.track(data.ahoyEvent, data);
        }
      });
    });
  }
};

window.bindAhoyEvents();
